
import { mapGetters, mapState } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Product from '../interfaces/product';

@Component({})
export default class Footer extends Vue {
  private showFooter = false;
  private showLimitations = false;

  @Getter('getProduct') private product!: Product;

  private toggleFooter() {
    this.showFooter = !this.showFooter;
  }
}
