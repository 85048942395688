export default {
  methods: {
    extractHtmlContents(html) {
      const element = ((new DOMParser())
        .parseFromString(html, "text/html").documentElement)
        .querySelector("body").firstElementChild;
      const attrNames = element.getAttributeNames();
      let attributes = {};
      attrNames.forEach(attr => {
        let attribute;
        if (attr === "href" && element.getAttribute("target") !== "_blank") {
          if (
            window.location.host === element.host ||
            element.host === 'www.assurity.com' ||
            element.host === 'assurity.com' ||
            element.host === 'staging.assurity.com'
          ) {
              attribute = { [attr]: element.getAttribute(attr).replace(element.origin, "") };
              if (attribute[attr].charAt(0) !== "/") {
                attribute[attr] = `/${attribute[attr]}`;
              }
            } else {
              attribute = { [attr]: element.getAttribute(attr) };
            }
        } else {
          attribute = { [attr]: element.getAttribute(attr) };
        }
        Object.assign(attributes, attribute);
      });
      return {
        attributes: attributes,
        tag: element.tagName,
        text: element.textContent,
      };
    },
    batch(arr, numOfArrays) {
      const itemsInBatch = arr.length / numOfArrays;
      return Array.from(new Array(numOfArrays))
        .map((x, index) =>
          arr.filter((y, i) =>
            i >= index * itemsInBatch && i < (index + 1) * itemsInBatch));
    },
    removeEmptyObjects(arr) {
      return arr.filter(item => Object.entries(item).length > 0);
    },
    limit(arr, index) {
      return arr.filter((item, i) => i < index);
    },
    skip(arr, index) {
      return arr.filter((item, i) => i >= index);
    },
  },
};
