import Vue from 'vue';
import Vuex from 'vuex';
import Product from '@/interfaces/product';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      age: null,
      gender: null,
    },
    product: {
      name: null,
      displayName: null,
      premium: null,
      faceAmount: null,
    },
  },
  getters: {
    getUser: (state) => state.user,
    getProduct: (state) => state.product,
  },
  mutations: {
    setUser: (state, user) => (state.user = user),
    setProduct: (state, product) => {
      state.product = Object.assign({}, state.product, product);
    },
  },
  actions: {},
  modules: {},
});
