
import { mapGetters, mapMutations } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';
import { Getter, Mutation } from 'vuex-class';

@Component({
  components: {
    Navigation,
    Footer,
  },
})
export default class App extends Vue {
  @Mutation private setProduct: any;
  @Getter private getProduct: any;

  private created() {
    // TODO: When product selection is added, we need to dynamically update the store to correct product.
    this.setProduct({
      name: 'spwl',
      displayName: 'Single Premium Whole Life',
    });
  }
}
