<template lang="pug">
  header.site-layout__header#site-layout__header
    a.skip-to-content-link(href="#site-main-content") Skip to Content
    // Desktop links
    nav.site-sidebar
      a.site-sidebar__logo-link(href='/')
        img.site-sidebar__logo(
          src='/images/assurity-logo.svg'
          alt='Assurity logo')
      button.site-sidebar__hamburger-container(
        :aria-expanded='`${mainMenuExpanded}`' 
        aria-controls='site-main-menu' 
        @click='toggleMainMenu()')
        img.site-sidebar__hamburger-icon(
          :src='mainMenuExpanded ? "/images/ic-close.svg" : "/images/ic-menu.svg"'
          alt='')
        span.sr-only(v-html='(mainMenuExpanded ? "Open" : "Close") + " main menu"')
      ul.site-sidebar__list#site-main-menu(v-if="data")
        // Top level navigation
      button.site-sidebar__login-icon-container(
        :aria-expanded='`${loginMenuExpanded}`' 
        aria-controls='site-login-menu' 
        @click='toggleLoginMenu()')
        img.site-sidebar__login-icon(
          :src='loginMenuExpanded ? "/images/group-up.svg" : "/images/group.svg"'
          alt='')
        span.sr-only(v-html='(loginMenuExpanded ? "Open" : "Close") + " login menu"')
</template>
<script>
import modifier from '@/mixins/modifier';

export default {
  mixins: [modifier],
  data() {
    return {
      data: null,
      mainMenuExpanded: false,
      loginMenuExpanded: false,
      sectionId: null,
      headerUserIsExpanded: false,
      openSubmenu: null,
    };
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeSubMenu);
  },
  methods: {
    assignData(data) {
      if (data) {
        this.data = data;
        return true;
      } else {
        return false;
      }
    },

    toggleMainMenu() {
      this.mainMenuExpanded = this.mainMenuExpanded ? false : true;
      this.loginMenuExpanded = false;
    },
    toggleLoginMenu() {
      this.loginMenuExpanded = this.loginMenuExpanded ? false : true;
    },
    changeOpenSection(sectionId) {
      this.openSubmenu = this.openSubmenu === sectionId ? null : sectionId;
    },
    getSubMenuHeight(itemCount) {
      const menuItemHeight = 50;

      return {
        maxHeight: `${itemCount * menuItemHeight}px`,
      };
    },
    closeSubMenu() {
      this.mainMenuExpanded = false;
      setTimeout(() => this.openSubmenu = null, 300);
    },
  },
  mounted() {
    // TODO: Turn this into a vue directive.
    // Close the main menu when you click outside of it
    document.addEventListener('click', (evt) => {
      const flyoutElement = document.getElementById('site-layout__header');
      let targetElement = evt.target; // clicked element

      do {
          if (targetElement === flyoutElement) {
              // This is a click inside. Do nothing, just return.
              return;
          }
          // Go up the DOM
          targetElement = targetElement.parentNode;
      } while (targetElement);

      // This is a click outside.
      this.openSubmenu = null;
    });
  },
};
</script>

<style lang="scss" scoped>
@use "../styles/common" as *;

.skip-to-content-link {
  background: $primary;
  color: $white;
  left: 50%;
  border: 1px solid $white;
  padding: .75em;
  position: fixed;
  top: 0;
  transform: translate(-50%, -150%);
  transition: transform .3s ease-in-out;
  z-index: 99;
  &:focus {
    transform: translate(-50%, 30px);
  }
}

.site-separator {
  background: url('/images/assurity-primary-dot.svg') repeat-x center;
  height: 2px;
  width: 100%;
  @include tablet-landscape {
    margin: 30px 0;
  }
  &--light {
    background: url('/images/assurity-primary-light-dot.svg') repeat-x center;
  }
}

.site-layout {
  &__header {
    display: block;
    position: fixed;
    width: 100%;
    height: $mobile-header-height;
    top: 0;
    left: 0;
    background: $primary;
    z-index: 8;
    @include tablet-landscape {
      height: 100vh;
      width: $desktop-header-width;
    }
    &-placeholder {
      height: $mobile-header-height;
      @include tablet-landscape {
        display: none;
      }
    }
  }
}
      
.site-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include tablet-landscape {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 23px 15px 15px;
    background-color: $primary;
  }

  &:before {
    content: '';
    position: absolute;
    height: $mobile-header-height;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $primary;
    z-index: 9;
    @include tablet-landscape {
      content: none;
    }
  }

  &__hamburger-container {
    position: absolute;
    left: 15px;
    top: 50%;
    padding: 10px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    appearance: none;
    z-index: 9;
    transition: transform .3s ease, left .3s ease, margin .3s ease;
    @include tablet-landscape {
      display: none;
    }

    &[aria-expanded="true"]  {
      transform: translate(-100%, -50%);
      left: calc(100% - 15px);

      ~ .site-sidebar__list {
        transform: none;
      }

      ~ .site-sidebar__logo-link {
        margin-left: calc(-50% + 15px);
        transform: translateX(50%);
      }

      ~ .site-sidebar__login-icon-container {
        transform: translate(200px, -50%);
      }
    }
  }

  &__logo-link {
    position: absolute;
    background-color: $primary;
    z-index: 10;
    transition: margin .3s ease, transform .3s ease;
    @include tablet-landscape {
      display: block;
      width: 100%;
      position: static;
      text-align: center;
    }
  }

  &__logo {
    height: 21px;
    margin: 10px;
    @include tablet-landscape {
      height: 30px;
      margin: 15px 0;
    }
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: $primary;
    transition: transform .3s ease;
    transform: translateX(-100%);
    padding: $mobile-header-height 20px 10px 20px;
    overflow-y: auto;
    list-style: none;
    @include tablet-landscape {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 0 auto;
      position: static;
      width: 100%;
      height: auto;
      transform: none;
      padding: 0 28px;
      overflow: visible;
    }
  }

  &__list-item {
    margin: 20px 0;
    @include tablet-landscape {
      margin: 5px 0;
    }

    &--expandable {
      transition: padding .3s ease;
      
      &.is-expanded {
        padding-left: 50px;
        @include tablet-landscape {
          padding-left: 0;
        }
      }
    }
  }

  &__list-link {
    display: block;
    color: $white;
    background: transparent;
    border: none;
    padding: 10px 0;
    line-height: 1;
    font-size: pxToRem(18);
    letter-spacing: 1px;
    @include tablet-landscape {
      font-size: pxToRem(14);
    }
      

    &:hover {
      color: rgba(255,255,255,0.85);
    }

    &--top-level {
      display: block;
      font-size: pxToRem(28);
      @include tablet-landscape {
        font-size: pxToRem(14);
      }
    }

    &--submenu {
      display: block;
      font-size: pxToRem(18);
      padding: 6px 0;
      line-height: 1;
      @include tablet-landscape {
        color: $primary;
        font-size: pxToRem(14);
        padding: 10px 0;
        &:hover {
          color: $primaryDark;
        }
      }
    }

    &--parent {
      position: relative;
      outline: none;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(-200%, -50%);
        height: 3px;
        width: 24px;
        background: $white;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
        @include tablet-landscape {
          content: none;
        }
      }
      
      &:after {
        content: '';
        position: absolute;
        background: url('/images/ic_chevron_right.svg') no-repeat center;
        background-size: contain;
        height: 100%;
        width: 14px;
        transform: translateX(100%);
        top: 0;
        right: -10px;
        transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
        @include tablet-landscape {
          width: 8px;
        }
      }

      &:hover {
        &:after {
          @include tablet-landscape {
            transform: translateX(150%);
          }
        }
      }

      &[aria-expanded='true'] {
        &:before {
          opacity: 1;
          visibility: visible;
          transform: translate(-100%, -50%);
        }

        &:after {
          opacity: 0;
          visibility: hidden;
          transform: translateX(150%);
          @include tablet-landscape {
            opacity: 1;
            visibility: visible;
            transform: translateX(200%);
          }
        }
        
        + .site-sidebar__submenu {
          @include tablet-landscape {
            transform: translateX(100%);
          }
        }
      }
    }
  }


  &__menu {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
  }

  &__submenu {
    max-height: 0;
    overflow: hidden;
    list-style: none;
    padding: 0;
    transition: max-height .3s ease, transform .3s ease;
    // max-height when sub menu is open is generated inline based on # of menu items
    // if you adjust height of sub menu items, remember to adjust the corresponding height in the "getSubMenuHeight()" method
    @include tablet-landscape {
      position: absolute;
      top: 0;
      left: 0;
      width: $desktop-header-width;
      height: 100vh;
      transform: translateX(-25%);
      max-height: none !important;
      z-index: -1;
      background: $white;
      padding: 83px 30px 43px 43px;
      box-shadow: 3px 0 12px rgba($black, .2);
    }
  }


  &__button {
    ::v-deep a {
      margin-top: 30px;
      margin-bottom: 5px;
      background-color: $primaryDark;
      border: none;
      display: block;
      width: 100%;
      font-size: pxToRem(16);
      letter-spacing: 3px;
      text-align: center;
      color: $white;
      line-height: 1.2;
      padding: 25px;
      transition: color .2s ease, background .2s ease;
      &:hover {
        background: scale-color($primaryDark, $lightness: -10%);
        color: rgba($white, .9);
      }
      @include tablet-landscape {
        padding: 15px;
        font-size: pxToRem(12);
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }


  &__social-bar {
    display: flex;
    justify-content: center;
    margin: 60px 0;
    align-items: center;
    padding: 0;
    list-style: none;
    @include tablet-landscape {
      justify-content: space-between;
      margin: 30px 0 5px;
    }
  }

  &__social-item {
    margin: 0 15px;
    @include tablet-landscape {
      margin: 0 5px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__social-link {
    &:hover {
      opacity: .85;
    }
    ::v-deep {
      img {
        height: 16px;
      }
    }
  }

  &__login-icon-container {
    position: absolute;
    z-index: 10;
    right: 15px;
    top: 50%;
    background: transparent;
    border: none;
    transform: translateY(-50%);
    transition: transform .3s ease;
    @include tablet-landscape {
      display: none;
    }

    &[aria-expanded='true'] ~ .site-sidebar__login-menu {
      transform: none;
    }
  }

  &__login-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    padding: 0 15px;
    margin: 0;
    background: $primaryDark;
    text-align: right;
    transform: translateY(-100%);
    transition: transform .3s ease;
    @include tablet-landscape {
      display: none;
    }
  }
}



</style>
